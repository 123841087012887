.transcript-container {
  width: 328px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 420px;
  gap: 12px;
  padding-right: 20px;
  position: absolute;
  right: 0;
  top: -430px;
  background: #fafafa;
  padding: 16px;
  border-radius: 16px;
  .scroll-container {
    max-height: 340px;
    overflow: scroll;
    scroll-behavior: smooth;
    &::webkit-scrollbar {
      display: none;
    }
  }
  .transcript {
    font-size: 14px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5em;
    .ts {
      font-size: 14px;
    }
    &.active {
      color: #F55B23;
    }
  }
}

.ts-close {
  position: absolute;
  right: 30px;
  top: -410px;
  width: 16px;
  cursor: pointer;
}

.ts-icon {
  width: 24px;
  margin-right: 10px;
  cursor: pointer;
}

.ts-title {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-bottom: 20px;
}
