.searchForm {
  display: inline-block;
  @item_w: 100%;
  @item_h: 50px;
  width: @item_w;
  position: relative;
  height: 100%;
  .input {
    line-height: 22px;
    background-color: transparent;
    width: @item_w;
    padding: 2px 80px;
    font-size: 18px;
    font-weight: 400;
    color: black;
    border: none;
    height: 100%;
  }
  .handleSubmit {
    position: absolute;
    padding: 0;
    left: 0;
    height: 50px;
    width: 50px;
    background-size: 22px 22px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../images/nav/search_icon-black.png);
  }

  .handleCancel {
    cursor: pointer;
    position: absolute;
    padding: 0;
    right: 0;
    height: 50px;
    width: 50px;
    background-size: 16px 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../images/nav/close_icon-black.png);
  }
  .suggestions {
    width: @item_w;
    &.showSuggestion {
      border-radius: 0 0 4px 4px;
      box-shadow: 0.02rem 0.1rem 0.3rem rgba(0, 0, 0, 0.2);
    }
  }
}
