/*
* base.less
*
* 此处存放基础设置下项：
* 1. 跟字号和rem缩放比例设置；
* 2. media查询区间变量
* 3. 一些字号的设置
*
*/
.font20bb {
  font-size: 20px;
  color: black;
  font-weight: 600;
}
.font14br {
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.font12cr {
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
}
.font16cr {
  font-size: 16px;
  color: #777;
  font-weight: 400;
}
.font30bb {
  font-size: 30px;
  color: black;
  font-weight: 600;
}
.font14cr {
  font-size: 14px;
  color: #777;
  font-weight: 400;
}
.font16br {
  font-size: 16px;
  color: black;
  font-weight: 400;
}
.font16bb {
  font-size: 16px;
  color: black;
  font-weight: 600;
}
.font20cb {
  font-size: 20px;
  color: #F55B23;
  font-weight: 600;
}
.font20cr {
  font-size: 20px;
  color: #777;
  font-weight: 400;
}
.font-xs-t1 {
  font-size: 22px;
  line-height: 1.3em;
  font-weight: 500;
  font-family: inherit;
}
.font-xs-t2 {
  font-size: 14px;
  line-height: 160%;
  font-weight: inherit;
  color: #9b9b9b;
  text-align: center;
  font-family: inherit;
}
.font_lg_t2 {
  font-size: 18px;
  line-height: 160%;
  font-weight: inherit;
  color: #9b9b9b;
  text-align: left;
  font-family: inherit;
}
html {
  font-size: 12px;
}
html #root {
  font-size: 14px;
}
.volumeCtrl {
  float: left;
  width: 20px;
  position: relative;
}
.volumeCtrl-img {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.volumeCtrl-sliderCon {
  transition: height 100ms;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  background-color: #fafafa;
  position: fixed;
  bottom: 48px;
  margin-left: -6px;
  width: 30px;
  height: 120px;
  padding: 10px 7px;
}
.volumeCtrl-sliderCon .rc-slider-vertical .rc-slider-rail {
  width: 2px;
}
.volumeCtrl-sliderCon .rc-slider-vertical .rc-slider-track {
  width: 2px;
  background-color: #F55B23;
}
.volumeCtrl-sliderCon .rc-slider-vertical .rc-slider-step {
  width: 2px;
}
.volumeCtrl-sliderCon .rc-slider-vertical .rc-slider-handle {
  width: 10px;
  height: 10px;
  margin-left: -4px;
}
