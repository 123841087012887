/*
* base.less
*
* 此处存放基础设置下项：
* 1. 跟字号和rem缩放比例设置；
* 2. media查询区间变量
* 3. 一些字号的设置
*
*/
.font20bb {
  font-size: 20px;
  color: black;
  font-weight: 600;
}
.font14br {
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.font12cr {
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
}
.font16cr {
  font-size: 16px;
  color: #777;
  font-weight: 400;
}
.font30bb {
  font-size: 30px;
  color: black;
  font-weight: 600;
}
.font14cr {
  font-size: 14px;
  color: #777;
  font-weight: 400;
}
.font16br {
  font-size: 16px;
  color: black;
  font-weight: 400;
}
.font16bb {
  font-size: 16px;
  color: black;
  font-weight: 600;
}
.font20cb {
  font-size: 20px;
  color: #F55B23;
  font-weight: 600;
}
.font20cr {
  font-size: 20px;
  color: #777;
  font-weight: 400;
}
.font-xs-t1 {
  font-size: 22px;
  line-height: 1.3em;
  font-weight: 500;
  font-family: inherit;
}
.font-xs-t2 {
  font-size: 14px;
  line-height: 160%;
  font-weight: inherit;
  color: #9b9b9b;
  text-align: center;
  font-family: inherit;
}
.font_lg_t2 {
  font-size: 18px;
  line-height: 160%;
  font-weight: inherit;
  color: #9b9b9b;
  text-align: left;
  font-family: inherit;
}
html {
  font-size: 12px;
}
html #root {
  font-size: 14px;
}
.textFontL {
  font-size: 2.5em;
  line-height: 120%;
}
.textFont3 {
  font-size: 1.875em;
  line-height: 120%;
}
.textFont5 {
  font-size: 1.5em;
  line-height: 120%;
}
.textFont6 {
  font-size: 1.25em;
  line-height: 120%;
}
.textFont8 {
  font-size: 1em;
  line-height: 120%;
}
.castboxMain {
  min-width: 960px;
  font-size: 14px;
  font-size: 10px;
}
.castboxMain a {
  color: inherit;
  text-decoration: none;
}
.castboxMain .mainBoxFooter {
  position: fixed;
}
.castboxMain #castboxHeader {
  width: 100%;
}
.castboxMain .childrenBox {
  width: 100%;
  padding-top: 0.7rem;
}
.castboxMain .childrenBox .castboxIndex {
  background-color: #f2f2f2;
}
#error404 {
  width: 100%;
  margin: 2.02rem auto 0;
  text-align: center;
}
#error404 #btn-box {
  display: flex;
  justify-content: center;
}
#error404 #btn-box .self_btn .retry {
  text-align: center;
  width: 33vw;
  padding: 0.18rem 0;
}
#error404 .top {
  margin: 0 auto;
  width: 2.26rem;
  height: 2.4rem;
  overflow: hidden;
}
#error404 .top img {
  width: 100%;
}
#error404 .title h1 {
  margin-top: 0.14rem;
  font-size: 1.875em;
  line-height: 120%;
  font-weight: 500;
}
#error404 .title p {
  margin-top: 0.14rem;
  font-size: 1.5em;
  line-height: 120%;
  color: #919191;
}
#error404 .self_btn {
  margin: 0.42rem auto;
}
#error404 .self_btn .retry {
  font-size: 1.875em;
  line-height: 120%;
  /* Rectangle 103: */
  border-radius: 4px;
  color: #f55b23;
  padding: 0.18rem 1.04rem;
  background-color: rgba(0, 0, 0, 0);
  border: #f55b23 solid 2px;
  box-shadow: 0 1px 2px 1px rgba(245, 91, 35, 0.25);
}
#error404 .self_btn .retry:active,
#error404 .self_btn .retry:hover {
  box-shadow: 0 1px 2px 1px rgba(245, 91, 35, 0.55);
}
#error404 .container {
  position: fixed;
  bottom: 0;
  background-color: #343434;
}
.coverRowLeft {
  width: 100%;
  height: 1.58rem;
}
.coverRowLeft .topCover {
  float: left;
  margin: 0.14rem 0 0;
  width: 1.3rem;
  height: 1.3rem;
}
.coverRowLeft .topCover img {
  width: 100%;
  height: 100%;
}
.coverRowLeft .bottomCon {
  height: 1.58rem;
  margin-left: 1.58rem;
  border-bottom: rgba(0, 0, 0, 0.12) solid 1px;
}
.coverRowLeft .bottomCon .title {
  margin-top: 0.41rem;
  font-size: 1.5em;
  line-height: 120%;
  font-weight: 500;
  width: 100%;
  height: 1.2em;
  line-height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.coverRowLeft .bottomCon .author {
  margin-top: 0.16rem;
  font-size: 1.25em;
  line-height: 120%;
  color: #666666;
  width: 100%;
  height: 1.2em;
  line-height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.castboxIndex {
  width: 960px;
  margin: 0 auto;
  background-color: #fff;
}
.castboxIndex .coverBox {
  margin-bottom: 0.2rem;
  background-color: #fff;
  padding: 0.1rem 12px;
}
.castboxIndex .coverBox .coverBoxHead {
  margin: 20px 1.6%;
  height: 0.28rem;
}
.castboxIndex .coverBox .coverBoxHead .coverBoxTitle {
  font-size: 20px;
  color: black;
  font-weight: 600;
  line-height: 0.28rem;
  height: 0.28rem;
  float: left;
}
.castboxIndex .coverBox .coverBoxHead .more {
  font-size: 16px;
  color: #777;
  font-weight: 400;
  line-height: 0.28rem;
  height: 0.28rem;
  float: right;
  text-decoration: underline;
}
.castboxIndex .coverBox .coverBoxHead .more a:focus,
.castboxIndex .coverBox .coverBoxHead .more a:active,
.castboxIndex .coverBox .coverBoxHead .more a:hover {
  color: #000;
}
.searchMain {
  width: 100%;
  color: #fff;
}
.searchMain .newkeywords {
  margin-top: 16px;
  font-size: 14px;
  line-height: 20px;
}
.searchMain .newkeywords .keyword,
.searchMain .newkeywords .keyword_new {
  font-size: 16px;
  font-weight: 500;
}
.searchMain .newkeywords .keyword {
  color: #f55b23;
}
.searchMain .serchForm {
  position: relative;
  width: 5.6rem;
  height: 0.72rem;
  padding: 0;
  margin: 0.88rem auto 0;
  border-bottom: #939393 solid 1px;
}
.searchMain .serchForm .searchTypeSel {
  display: none;
}
.searchMain .serchForm .input {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  height: 0.72rem;
  width: 100%;
  padding: 0 0.88rem 0 0.16rem;
  font-size: 2.5em;
  line-height: 120%;
  font-weight: 300;
  line-height: 0.72rem;
}
.searchMain .serchForm .handleSubmit {
  position: absolute;
  top: -6px;
  right: 0;
  width: 0.65rem;
  height: 0.72rem;
  background: url('/app/castbox/static/images/searchIcon.png') no-repeat center center;
  background-size: 0.58rem 0.64rem;
  cursor: pointer;
}
.searchMain .searchResult {
  padding-top: 0.2rem;
}
.searchMain .searchResult .genreList {
  padding: 40px 0 0;
  text-align: left;
}
.searchMain .searchResult .genreList .genreRow {
  display: block;
  margin: 0 0 30px;
  background-color: #fff;
}
.searchMain .searchResult .genreList .genreRow .coverRow {
  width: 100%;
  height: 80px;
  margin: 0;
}
.searchMain .searchResult .genreList .genreRow .coverRow .indexNum {
  height: 80px;
  width: 40px;
  text-align: left;
  float: left;
  font-size: 20px;
  line-height: 28px;
  padding-top: 20px;
}
.searchMain .searchResult .genreList .genreRow .coverRow .topCover {
  float: left;
  margin: 0;
  width: 80px;
  height: 80px;
}
.searchMain .searchResult .genreList .genreRow .coverRow .topCover img {
  width: 100%;
  height: 100%;
}
.searchMain .searchResult .genreList .genreRow .coverRow .bottomCon {
  text-align: left;
  width: 395px;
  float: left;
  height: 80px;
  padding: 0 20px;
  margin: 0;
  border-bottom: 0;
}
.searchMain .searchResult .genreList .genreRow .coverRow .bottomCon .title {
  margin-top: 12px;
  font-size: 14px;
  color: black;
  font-weight: 400;
  width: 100%;
  height: 1.2em;
  line-height: 1.2em;
  overflow: hidden;
}
.searchMain .searchResult .genreList .genreRow .coverRow .bottomCon .author {
  margin-top: 8px;
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
  font-weight: normal;
  width: 100%;
  height: 2.4em;
  line-height: 1.2em;
  overflow: hidden;
}
.searchMain .searchResult .genreList .genreRow .coverRow .row-sub {
  float: right;
  margin-right: 30px;
  width: 30px;
  height: 80px;
  position: relative;
}
.searchMain .searchResult .genreList .genreRow .coverRow .row-sub-img {
  cursor: pointer;
  background-image: url('../images/subBtn.png');
  background-size: 100%;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -15px;
  margin-left: -15px;
}
.searchMain .searchResult .genreList .genreRow .coverRow .row-sub .is-subed .row-sub-img {
  background-image: url('../images/subedBtn.png');
}
.searchMain .searchResult .empty {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 3rem;
  margin-left: -1.5rem;
  font-size: 1.5em;
  line-height: 120%;
  color: rgba(255, 255, 255, 0.6);
  padding-top: 0.2rem;
  text-align: center;
}
.searchMain .searchResult .keywords {
  text-align: left;
}
.searchMain .searchResult .keywords .keyword {
  margin: 12px 15px;
  padding: 0.12rem 15px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.4em;
  vertical-align: top;
}
.leftContainer {
  width: 599px;
  float: left;
  border-right: 1px solid #dcdcdc;
}
.rightContainer {
  width: 320px;
  float: left;
}
.castboxMain {
  background-color: #f2f2f2;
}
.castboxMain .childrenBox {
  padding-top: 60px;
}
.castboxMain .childrenBox .castboxIndex .coverBoxHead,
.castboxMain .childrenBox .castboxIndex .coverBoxList,
.castboxMain .childrenBox .categoriesMain,
.castboxMain .childrenBox .networksMain {
  width: 960px;
  margin: 0 auto;
}
.castboxMain .childrenBox .genreMain,
.castboxMain .childrenBox .searchMain {
  max-width: 960px;
  margin: 0 auto;
  background-color: #fff;
}
.castboxMain #error404 #btn-box {
  display: flex;
  justify-content: center;
}
.castboxMain #error404 #btn-box .self_btn {
  margin: 30px 20px;
}
.castboxMain #error404 #btn-box .self_btn .retry {
  text-align: center;
  width: 200px;
  padding: 13px 0;
}
.castboxMain #error404 .top {
  width: 168px;
  height: 180px;
  overflow: hidden;
}
.castboxMain #error404 .top img {
  width: 100%;
}
.castboxMain #error404 .title h1 {
  font-size: 24px;
  font-weight: 500;
}
.castboxMain #error404 .title p {
  margin-top: 8px;
  font-size: 20px;
}
.castboxMain #error404 .self_btn {
  margin: 30px auto;
}
.castboxMain #error404 .self_btn .retry {
  font-size: 20px;
  /* Rectangle 103: */
  border-radius: 4px;
  padding: 14px 74px;
}
.castboxMain #error404 .container {
  display: none;
}
.castboxMain #castboxHeader {
  position: fixed;
  z-index: 98;
}
.castboxIndex .recommendation {
  margin-top: 0;
}
.castboxIndex .topBoxLink {
  height: 86px;
  padding-bottom: 20px;
  text-align: center;
}
.castboxIndex .topBoxLink-btn {
  font-size: 20px;
  color: black;
  font-weight: 600;
  width: 200px;
  height: 46px;
  display: inline-block;
  color: #fdfdfd;
  line-height: 46px;
  background-color: #F55B23;
  border-radius: 4px;
}
.castboxIndex .recommendation,
.castboxIndex .topPodcast {
  padding-top: 0;
}
.castboxIndex .recommendation .coverBoxHead,
.castboxIndex .topPodcast .coverBoxHead {
  height: auto;
  padding: 20px;
}
.castboxIndex .recommendation .coverBoxHead .coverBoxTitle,
.castboxIndex .topPodcast .coverBoxHead .coverBoxTitle,
.castboxIndex .recommendation .coverBoxHead .more,
.castboxIndex .topPodcast .coverBoxHead .more {
  height: 28px;
  line-height: 28px;
}
.castboxIndex .recommendation .coverBoxHead coverBoxTitle,
.castboxIndex .topPodcast .coverBoxHead coverBoxTitle {
  font-size: 20px;
  font-weight: bold;
}
.castboxIndex .recommendation .coverBoxList,
.castboxIndex .topPodcast .coverBoxList {
  padding: 0 5px 42px;
  text-align: left;
}
.castboxIndex .recommendation .coverBoxList .coverRow,
.castboxIndex .topPodcast .coverBoxList .coverRow {
  text-align: left;
}
.castboxIndex .recommendation .coverBoxList .coverRow {
  text-align: left;
}
.castboxIndex .recommendation .coverBoxList .coverRow:last-child .bottomCon {
  border-bottom: rgba(0, 0, 0, 0) solid 1px;
}
.castboxIndex .topPodcast .coverBoxList .coverRow .bottomCon {
  background-color: rgba(0, 0, 0, 0);
  color: #505050;
}
.castboxIndex .coverBox {
  padding: 0;
  margin-bottom: 0;
}
.castboxIndex .coverBox:nth-child(3n + 4) {
  border-radius: 4px;
  background-color: #f6f6f6;
}
.castboxIndex .coverBox:nth-child(3n + 4) .coverBoxList .coverRow .bottomCon {
  padding: 10px 0;
}
.castboxIndex .coverBox:nth-child(3n + 4) .coverBoxList .coverRow.rowLeft .bottomCon {
  padding: 0 20px;
}
.castboxIndex .episode_list .coverBoxList {
  padding: 0 20px 0 30px;
}
.castboxIndex .episode_list .coverBoxList .episodeRow {
  width: 455px;
  float: left;
  margin: 0 0 20px;
  padding: 0;
  height: 100px;
  border: none;
}
.castboxIndex .episode_list .coverBoxList .episodeRow:nth-child(2n + 1) {
  padding-right: 10px;
}
.castboxIndex .episode_list .coverBoxList .episodeRow .ep-item {
  margin: 0;
}
.castboxIndex .episode_list .coverBoxList .episodeRow .ep-item-con-des {
  font-size: 12px;
}
.genreMain {
  padding: 20px 20px 10px;
}
.genreMain .topCategory {
  padding-top: 20px;
}
.genreMain .sub-categories {
  padding-top: 8px;
}
.genreMain .sub-categories .sub-categorie {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  margin-right: 12px;
  margin-top: 12px;
  padding: 0 14px;
  font-size: 12px;
  color: #757575;
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: 14px;
}
.genreMain .sub-categories .sub-categorie:hover,
.genreMain .sub-categories .sub-categorie:focus,
.genreMain .sub-categories .sub-categorie:active,
.genreMain .sub-categories .sub-categorie.active {
  background: #F55B23;
  color: #fff;
  border-color: #F55B23;
}
.genreMain .genreList {
  padding: 40px 0 0;
  text-align: left;
}
.genreMain .genreList .genreRow {
  display: block;
  margin: 0 0 30px;
  background-color: #fff;
}
.genreMain .genreList .genreRow .coverRow {
  width: 100%;
  height: 80px;
  margin: 0;
}
.genreMain .genreList .genreRow .coverRow .indexNum {
  height: 80px;
  width: 40px;
  text-align: left;
  float: left;
  font-size: 20px;
  line-height: 28px;
  padding-top: 20px;
}
.genreMain .genreList .genreRow .coverRow .topCover {
  float: left;
  margin: 0;
  width: 80px;
  height: 80px;
}
.genreMain .genreList .genreRow .coverRow .topCover img {
  width: 100%;
  height: 100%;
}
.genreMain .genreList .genreRow .coverRow .bottomCon {
  text-align: left;
  width: 395px;
  float: left;
  height: 80px;
  padding: 0 20px;
  margin: 0;
  border-bottom: 0;
}
.genreMain .genreList .genreRow .coverRow .bottomCon .title {
  margin-top: 12px;
  font-size: 14px;
  color: black;
  font-weight: 400;
  width: 100%;
  height: 1.2em;
  line-height: 1.2em;
  overflow: hidden;
}
.genreMain .genreList .genreRow .coverRow .bottomCon .author {
  margin-top: 8px;
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
  font-weight: normal;
  width: 100%;
  height: 2.4em;
  line-height: 1.2em;
  overflow: hidden;
}
.genreMain .genreList .genreRow .coverRow .row-sub {
  float: right;
  margin-right: 30px;
  width: 30px;
  height: 80px;
  position: relative;
}
.genreMain .genreList .genreRow .coverRow .row-sub-img {
  cursor: pointer;
  background-image: url('../images/subBtn.png');
  background-size: 100%;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -15px;
  margin-left: -15px;
}
.genreMain .genreList .genreRow .coverRow .row-sub .is-subed .row-sub-img {
  background-image: url('../images/subedBtn.png');
}
.networksMain .genreList {
  padding: 22px;
  text-align: left;
}
.networksMain .genreList .genreRow {
  display: inline-block;
  width: 180px;
  height: 260px;
  margin: 0 8px 16px;
  background-color: #fff;
  box-shadow: 2px 3px 5px 0 rgba(0, 0, 0, 0.1);
}
.networksMain .genreList .genreRow .coverRow {
  display: inline-block;
  width: 180px;
  height: 260px;
}
.networksMain .genreList .genreRow .coverRow .topCover {
  width: 180px;
  height: 180px;
}
.networksMain .genreList .genreRow .coverRow .topCover img {
  width: 100%;
  height: 100%;
}
.networksMain .genreList .genreRow .coverRow .bottomCon {
  text-align: left;
  width: 180px;
  height: 80px;
  padding: 0 14px;
  margin: 0;
  position: relative;
}
.networksMain .genreList .genreRow .coverRow .bottomCon .count {
  font-size: 1em;
  line-height: 120%;
  position: absolute;
  width: 26px;
  height: 26px;
  max-width: 32px;
  max-height: 32px;
  line-height: 26px;
  border-radius: 50%;
  background-color: #f59716;
  top: 8px;
  right: 8px;
  text-align: center;
  color: #fff;
}
.networksMain .genreList .genreRow .coverRow .bottomCon .title {
  margin-top: 14px;
  font-size: 1.25em;
  line-height: 120%;
  padding-right: 32px;
  font-weight: 500;
  width: 100%;
  height: 1.2em;
  line-height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.networksMain .genreList .genreRow .coverRow .bottomCon .author {
  margin-top: 10px;
  font-size: 1em;
  line-height: 120%;
  font-weight: normal;
  width: 100%;
  height: 1.2em;
  line-height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.searchMain {
  position: relative;
  color: #000;
  background-color: rgba(0, 0, 0, 0);
}
.searchMain .serchForm {
  position: relative;
  width: 550px;
  height: 37px;
  padding: 0;
  margin: 40px auto 0;
}
.searchMain .serchForm .searchTypeSel {
  display: block;
  position: absolute;
  bottom: 0;
}
.searchMain .serchForm .searchTypeSel .Select-control {
  background-color: #dcdcdc;
  border-radius: 4px 0 0 4px;
}
.searchMain .serchForm input:-webkit-autofill {
  background-color: rgba(0, 0, 0, 0);
  color: initial;
}
.searchMain .serchForm .input {
  position: absolute;
  left: 96px;
  background-color: rgba(0, 0, 0, 0);
  height: 37px;
  width: 418px;
  padding: 0 15px;
  border: #939393 solid 1px;
  font-size: 2.5em;
  line-height: 120%;
  font-size: 1.4em;
  font-weight: 300;
  line-height: 37px;
}
.searchMain .serchForm .handleSubmit {
  position: absolute;
  border: #939393 solid 1px;
  border-left: 0;
  border-radius: 0 4px 4px 0;
  top: 0;
  right: 0;
  padding: 6px;
  width: 37px;
  height: 37px;
  background: url('/app/castbox/static/images/searchIcon.png') no-repeat center center;
  background-size: auto 23px;
}
.searchMain .searchResult {
  padding-top: 0.2rem;
}
.searchMain .searchResult .genreList {
  padding: 40px 0 0;
  text-align: left;
  color: #000;
}
.searchMain .searchResult .genreList .genreRow {
  display: block;
  margin: 0 0 30px;
  background-color: #fff;
}
.searchMain .searchResult .genreList .genreRow .coverRow {
  width: 100%;
  height: 80px;
  margin: 0;
}
.searchMain .searchResult .genreList .genreRow .coverRow .indexNum {
  height: 80px;
  width: 40px;
  text-align: left;
  float: left;
  font-size: 20px;
  line-height: 28px;
  padding-top: 20px;
}
.searchMain .searchResult .genreList .genreRow .coverRow .topCover {
  float: left;
  margin: 0;
  width: 80px;
  height: 80px;
}
.searchMain .searchResult .genreList .genreRow .coverRow .topCover img {
  width: 100%;
  height: 100%;
}
.searchMain .searchResult .genreList .genreRow .coverRow .bottomCon {
  text-align: left;
  width: 395px;
  float: left;
  height: 80px;
  padding: 0 20px;
  margin: 0;
  border-bottom: 0;
}
.searchMain .searchResult .genreList .genreRow .coverRow .bottomCon .title {
  margin-top: 12px;
  font-size: 14px;
  color: black;
  font-weight: 400;
  width: 100%;
  height: 1.2em;
  line-height: 1.2em;
  overflow: hidden;
}
.searchMain .searchResult .genreList .genreRow .coverRow .bottomCon .author {
  margin-top: 8px;
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
  font-weight: normal;
  width: 100%;
  height: 2.4em;
  line-height: 1.2em;
  overflow: hidden;
}
.searchMain .searchResult .genreList .genreRow .coverRow .row-sub {
  float: right;
  margin-right: 30px;
  width: 30px;
  height: 80px;
  position: relative;
}
.searchMain .searchResult .genreList .genreRow .coverRow .row-sub-img {
  cursor: pointer;
  background-image: url('../images/subBtn.png');
  background-size: 100%;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -15px;
  margin-left: -15px;
}
.searchMain .searchResult .genreList .genreRow .coverRow .row-sub .is-subed .row-sub-img {
  background-image: url('../images/subedBtn.png');
}
.searchMain .searchResult .empty {
  font-size: 1.5em;
  line-height: 120%;
  color: #000;
  padding-top: 0.2rem;
  text-align: center;
}
.searchMain .searchResult .keywords {
  width: 550px;
  margin: 0 auto;
  padding: 0;
}
.searchMain .searchResult .keywords .keyword {
  color: #000;
  margin: 12px 0;
  padding: 0.12rem 0;
}
