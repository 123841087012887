#castboxHeader {
  @h: 50px;
  background-color: white;
  height: @h;
  color: black;
  border-bottom: 2px solid #f55b23;
  .castboxHeaderContainer {
    width: 960px;
    height: 100%;
    margin: 0 auto;
  }
  .nav-item {
    height: @h;
    vertical-align: top;
    margin: 0 15px;
    font-size: 14px;
    line-height: @h;
    &.link {
      &.active,
      &:hover {
        color: #f55b23;
      }
    }
    .text {
      margin-left: 10px;
      font-size: 14px;
      line-height: @h;
      &:active,
      &:hover {
        text-decoration: underline;
      }
    }
    &.right {
      float: right;
    }
    &.left {
      float: left;
    }
    &.upload {
      .img {
        width: 34px;
      }
    }

    &.search {
      .img {
        cursor: pointer;
        width: 34px;
      }
    }
  }
  .navLogo {
    .logoTextCover {
      height: 35px;
      margin-right: 15px;
    }
  }
  .topUserNav {
    margin: 0;
    padding: 0;
  }

  .navSearch {
    width: 370px;
    float: right;
    .searchForm .input {
      padding: 2px 50px;
    }
  }
}
