.msg-nav {
  @w: 280px;
  @h: 50px;
  position: relative;
  height: 50px;
  padding: 8px 0;
  &-icon {
    cursor: pointer;
    height: 100%;
    width: 100%;
    .img {
      vertical-align: top;
      width: 34px;
      height: 34px;
    }
  }
  .msglist {
    display: none;
    position: absolute;
    right: -64px;
    top: @h;
    width: @w;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
      0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
    border-radius: 0 0 4px 4px;
  }
  &.show .msglist {
    display: block;
  }
}
.msglist {
  max-height: 483px;
  background: #fff;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 12px 0 12px 12px;
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(158, 158, 158, 0.6);
    &:hover {
      background-color: rgba(158, 158, 158, 0.9);
    }
  }
}
.msg-item {
  @cover_h: 40px;
  @link_cover_h: 50px;
  width: 100%;
  padding: 10px 12px 10px 0;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  border-top: #ccc solid 1px;
  &:first-child {
    border-top: none;
  }
  &-cover {
    height: @cover_h;
    width: @cover_h;
    flex-shrink: 0;
    .img {
      vertical-align: top;
      overflow: hidden;
      height: @cover_h;
      width: @cover_h;
      border-radius: @cover_h / 2;
      width: 100%;
    }
  }
  &-info {
    padding-left: 10px;
    display: block;
    overflow: hidden;
    flex: 1;
    &-t {
      font-size: 14px;
      opacity: 0.7;
    }
    &-d {
      margin-top: 6px;
      font-size: 12px;
    }
    &-date {
      margin-top: 10px;
      font-size: 12px;
      opacity: 0.6;
    }
  }
  &-link {
    width: @link_cover_h;
    height: @link_cover_h;
    overflow: hidden;
    display: inline-block;
    flex-shrink: 0;
    .img {
      width: 100%;
      vertical-align: top;
      overflow: hidden;
      border-radius: 4px;
    }
  }
}
