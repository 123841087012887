.font20bb {
  font-size: 20px;
  color: black;
  font-weight: 600;
}
.font14br {
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.font12cr {
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
}
.font16cr {
  font-size: 16px;
  color: #777;
  font-weight: 400;
}
.font30bb {
  font-size: 30px;
  color: black;
  font-weight: 600;
}
.font14cr {
  font-size: 14px;
  color: #777;
  font-weight: 400;
}
.font16br {
  font-size: 16px;
  color: black;
  font-weight: 400;
}
.font16bb {
  font-size: 16px;
  color: black;
  font-weight: 600;
}
.font20cb {
  font-size: 20px;
  color: #F55B23;
  font-weight: 600;
}
.font20cr {
  font-size: 20px;
  color: #777;
  font-weight: 400;
}
.castboxFooterBar {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 46px;
  zoom: 1;
  box-shadow: 0 -5px 8px 0 rgba(240, 240, 240, 0.8);
  background-color: #fafafa;
  z-index: 111;
}
.castboxFooterBar .footemain {
  width: 960px;
  margin: 0 auto;
  padding: 0 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  position: relative;
}
.castboxFooterBar .footemain .PlayControl,
.castboxFooterBar .footemain .playbackRate,
.castboxFooterBar .footemain .volumeCtrl {
  flex-shrink: 0;
}
.castboxFooterBar .footemain .PlayControl {
  height: 18px;
}
.castboxFooterBar .footemain .PlayControl .icon {
  display: inline-block;
  cursor: pointer;
  width: 14px;
  height: 18px;
  margin: 0 21px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 16px;
}
.castboxFooterBar .footemain .PlayControl .back {
  background-image: url('../../images/back15.png');
  background-size: 10px 16px;
}
.castboxFooterBar .footemain .PlayControl .forward {
  background-image: url('../../images/go30.png');
}
.castboxFooterBar .footemain .PlayControl .playBtn {
  transition: 0.5s;
}
.castboxFooterBar .footemain .PlayControl .loading {
  background-size: 14px 14px;
}
.castboxFooterBar .footemain .PlayControl .pause {
  background-image: url('../../images/footerPause.png');
}
.castboxFooterBar .footemain .PlayControl .play {
  background-image: url('../../images/footerPlay.png');
}
.castboxFooterBar .footemain .PlayControl .play.loading {
  background-image: url('../../images/footerloading3.gif');
}
.castboxFooterBar .footemain .sticker {
  flex: 1;
}
.castboxFooterBar .footemain .sticker .player-stick {
  width: 100%;
  height: 46px;
  padding-right: 20px;
}
.castboxFooterBar .footemain .sticker .player-stick .current-time,
.castboxFooterBar .footemain .sticker .player-stick .duration {
  font-size: 14px;
  color: #777;
  font-weight: 400;
  min-width: 56px;
  height: 46px;
  line-height: 46px;
}
.castboxFooterBar .footemain .sticker .player-stick .player-stick-coat {
  margin: 0 10px;
  height: 100%;
  padding: 21px 0;
  vertical-align: middle;
}
.castboxFooterBar .footemain .sticker .player-stick .player-stick-coat .PlayerStickBar {
  width: 100%;
  position: relative;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  background-color: #dcdcdc;
  padding: 0;
  height: 4px;
  margin: 0;
}
.castboxFooterBar .footemain .sticker .player-stick .player-stick-coat .PlayerStickBar .playerStick {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  background-color: #F55B23;
  width: 0;
  margin: 0 -1px;
  height: 4px;
}
.castboxFooterBar .footemain .sticker .player-stick .player-stick-coat .PlayerStickBar .circleBar {
  position: absolute;
  top: -5px;
  left: -5px;
  width: 14px;
  height: 14px;
  margin-left: -7px;
  background-color: #F55B23;
  border: #fff solid 2px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.castboxFooterBar .footemain .playbackRate {
  width: 30px;
  margin: 0 15px 0 0;
  height: 46px;
  font-size: 14px;
  position: relative;
}
.castboxFooterBar .footemain .playbackRate-value {
  cursor: pointer;
  height: 46px;
  width: 100%;
  line-height: 46px;
  text-align: center;
}
.castboxFooterBar .footemain .playbackRate-list {
  position: absolute;
  left: 50%;
  top: -8px;
  transform: translate(-50%, -100%);
  width: 100px;
  background-color: #fafafa;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(240, 240, 240, 0.8);
}
.castboxFooterBar .footemain .playbackRate-list.hide {
  height: 0;
}
.castboxFooterBar .footemain .playbackRate-list-item {
  padding: 0;
  text-align: center;
  cursor: pointer;
  line-height: 30px;
  margin: 0;
  border-bottom: 1px solid #f2f2f2;
}
.castboxFooterBar .footemain .playbackRate-list-item:hover,
.castboxFooterBar .footemain .playbackRate-list-item:active {
  color: #000;
  background-color: #ccc;
}
.castboxFooterBar .footemain .playbackRate.timing {
  width: 36px;
}
.castboxFooterBar .footemain .playbackRate.timing.long {
  width: 48px;
}
.castboxFooterBar .footemain .playbackRate.timing.icon {
  width: 16px;
}
.castboxFooterBar .footemain .playbackRate.timing.icon .playbackRate-value img {
  height: 16px;
}
.castboxFooterBar .footemain .playbackRate.timing .playbackRate-list {
  width: 120px;
}
.castboxFooterBar .footemain .playbackRate.timing .playbackRate-list-title {
  background-color: #f2f2f2;
  text-align: center;
  padding: 6px 14px;
  border-bottom: 1px solid #ccc;
}
.castboxFooterBar .footemain .volumeCtrl {
  margin-right: 10px;
}
.castboxFooterBar .footemain .footerFeed {
  display: flex;
  flex: 1;
  width: 256px;
  justify-content: flex-start;
}
.castboxFooterBar .footemain .footerFeed .leftImg {
  width: 46px;
  height: 46px;
  flex-shrink: 0;
  border-radius: 4px;
}
.castboxFooterBar .footemain .footerFeed .rightText {
  flex: 1;
  overflow: hidden;
  padding-left: 12px;
  padding-top: 6px;
}
.castboxFooterBar .footemain .footerFeed .rightText .title,
.castboxFooterBar .footemain .footerFeed .rightText .author {
  width: 100%;
  height: 18px;
  line-height: 18px;
}
.castboxFooterBar .footemain .footerFeed .rightText .title {
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.castboxFooterBar .footemain .footerFeed .rightText .author {
  margin-top: 2px;
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
}
