.player-stick {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  .current-time,
  .duration {
    flex-shrink: 0;
    text-align: center;
  }
  .current-time {
    text-align: left;
  }
  .player-stick-coat {
    flex: 1;
  }
}
